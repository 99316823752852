import {Service} from './service'

export function wxcheck(data){
    return Service({
        url: '/api/wechat/check',
        method: "get",
        params: {openid: data}
    })
}
export function alicheck(data){
    return Service({
        url: '/api/ali/check?',
        method: "get",
        params: {user_id: data}
    })
}
export function wechatAuthorization(data){
    return Service({
        url: '/api/wechat/wechatAuthorization',
        method: "get",
        params: {code: data}
    })
}
export function aliSign(data){
    return Service({
        url: '/api/ali/aliSign',
        method: "get",
        params: {code: data.code, type: data.type}
    })
}
export function getAuthToken(data){
    return Service({
        url: '/api/get_auth_token',
        method: "post",
        data: data
    })
}
export function getPhoneWithToken(data){
    return Service({
        url: '/api/get_phone_with_token',
        method: "post",
        data: data
    })
}
export function binding(data){
    return Service({
        url: '/api/third/binding',
        method: "get",
        params: {phone: data.phone, sms: data.sms, sms_code: data.sms_code, third_user_id: data.third_user_id, type: data.type}
    })
}
export function getMerInfo(data){
    return Service({
        url: '/api/merchant/get_mer_info',
        method: "get",
        params: {mer_id: data}
    })
}
export function createOrder(data){
    return Service({
        url: '/api/merchant/createOrder',
        method: "post",
        data: data
    })
}
export function jsapiPay(data){
    return Service({
        url: '/api/merchant/jsapiPay',
        method: "post",
        data: data
    })
}
export function createAnnualOrder(data){
    return Service({
        url: '/api/user/create_annual_order',
        method: "post",
        data: data
    })
}
export function payUserAnnualOffline(data){
    return Service({
        url: '/api/pay_user_annual_offline',
        method: "post",
        data: data
    })
}
export function userPhoneLogOnCode(data){
    return Service({
        url: '/api/auth/verify',
        method: "post",
        data: data
    })
}