import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/HelloWorld'
import OrderPay from '../components/OrderPay'
import OpenPension from '../components/OpenPension'
import UserLogin from '../components/UserLogin'
import SuccessFully from '../components/SuccessFully'
// import { Message } from "element-ui";


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/orderPay',
    name: 'OrderPay ',
    component: OrderPay,
  },
  {
    path: '/openPension',
    name: 'OpenPension ',
    component: OpenPension,
  },
  {
    path: '/userLogin',
    name: 'UserLogin ',
    component: UserLogin,
  },
  {
    path: '/successFully',
    name: 'SuccessFully ',
    component: SuccessFully,
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
//   if (to.meta.requireAuth) {
//     var num = localStorage.getItem('token');
//     if (num) {
//       next();
//     }
//     else {
//       Message({
//         message: "请授权",
//         type: "error",
//         duration: 2000,
//       });
//       next({
//         path: '/',
//       })
//     }
//   }
//   else {
    next();
//   }
}
)


export default router
