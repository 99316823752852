<template>
  <div id="pension">
    <div>
      <img class="pension-header" src="https://cdn.bjtdba.com/vod/image/2023-04-17/20230417145316773.png" alt="">
      <div class="pension-open" @click="createAnnual(1)">
        <img class="pension-openImg" src="https://cdn.bjtdba.com/vod/image/2023-04-17/20230417145331307.png" alt="">
        <div class="openImg-title">开通养老金账号 管理年费</div>
        <div class="openImg-amount"><span class="amount-icon">¥</span>12</div>
      </div>
    </div>
    <div class="pension-lists">
      <div class="pension-list">
        <img class="pension-list-img" src="https://cdn.bjtdba.com/vod/image/2023-04-17/20230417145346223.png" alt="">
        <div class="pension-list-pack">
          <img class="pension-list-packImg" src="https://cdn.bjtdba.com/vod/image/2023-04-17/20230417145418188.png"
            alt="">
          <div class="pension-list-packTxt">大礼包</div>
        </div>
        <div class="pension-list-amount">
          <div class="pension-list-amount-icon">¥</div>
          <div>48</div>
        </div>
        <div class="pension-list-txt">包含商品、养老金、 养老金账号管理费</div>
        <div class="pension-list-btn" @click="createAnnual(2)">
          <img class="pension-list-btnImg" src="https://cdn.bjtdba.com/vod/image/2023-04-17/20230417145405204.png" alt="">
          <div class="pension-list-btnTxt">立即开通</div>
        </div>
      </div>
      <div class="pension-list">
        <img class="pension-list-img" src="https://cdn.bjtdba.com/vod/image/2023-04-17/20230417145346223.png" alt="">
        <div class="pension-list-pack">
          <img class="pension-list-packImg" src="https://cdn.bjtdba.com/vod/image/2023-04-17/20230417145418188.png"
            alt="">
          <div class="pension-list-packTxt">大礼包</div>
        </div>
        <div class="pension-list-amount">
          <div class="pension-list-amount-icon">¥</div>
          <div>68</div>
        </div>
        <div class="pension-list-txt">包含商品、养老金、 养老金账号管理费</div>
        <div class="pension-list-btn" @click="createAnnual(3)">
          <img class="pension-list-btnImg" src="https://cdn.bjtdba.com/vod/image/2023-04-17/20230417145405204.png" alt="">
          <div class="pension-list-btnTxt">立即开通</div>
        </div>
      </div>
      <div class="pension-list">
        <img class="pension-list-img" src="https://cdn.bjtdba.com/vod/image/2023-04-17/20230417145346223.png" alt="">
        <div class="pension-list-pack">
          <img class="pension-list-packImg" src="https://cdn.bjtdba.com/vod/image/2023-04-17/20230417145418188.png"
            alt="">
          <div class="pension-list-packTxt">大礼包</div>
        </div>
        <div class="pension-list-amount">
          <div class="pension-list-amount-icon">¥</div>
          <div>88</div>
        </div>
        <div class="pension-list-txt">包含商品、养老金、 养老金账号管理费</div>
        <div class="pension-list-btn" @click="createAnnual(4)">
          <img class="pension-list-btnImg" src="https://cdn.bjtdba.com/vod/image/2023-04-17/20230417145405204.png" alt="">
          <div class="pension-list-btnTxt">立即开通</div>
        </div>
      </div>
    </div>

  </div>
</template>
  
<script>
import wx from 'weixin-js-sdk';
import { createAnnualOrder, payUserAnnualOffline } from '../api/index'
export default {
  name: 'OpenPension',
  props: {
    msg: String
  },
  data() {
    return {
      trade_type: '',
      userInfor: {},
      aliAccount: {},
      outTradeNo: ''
    }
  },
  created() {
    //不是微信并且不是支付宝
    if (process.env.NODE_ENV !== "development") { //非开发环境
      if (!this.$isWechat() && !this.$isAlipay()) {
        alert('请使用微信或支付宝扫码');
      }
    }

    this.userpay = this.$route.query.userpay;
    this.userInfor = this.$route.query.userInfor;
    this.aliAccount = this.$route.query.aliAccount;
    //   //判断是不是微信
    if (this.$isWechat()) {
      this.trade_type = "WX"
    }
    //   //判断是不是支付宝
    if (this.$isAlipay()) {
      this.trade_type = "ALI"
    }
  },
  // 引入支付宝jsapi
  mounted() {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.src = 'https://gw.alipayobjects.com/as/g/h5-lib/alipayjsapi/3.1.1/alipayjsapi.min.js';
    document.body.appendChild(s);
    window.onload = () => {   //初始化产生一个全局变量 AlipayJSBridge
      this.readys()
    }
  },
  methods: {
    createAnnual(type) {
      let that = this;
      createAnnualOrder({ type: type }).then(res => {
        console.log('创建大礼包订单', res);
        if (res.status == 200) {
          that.outTradeNo = res.data.order_id;
          payUserAnnualOffline({
            oid: res.data.order_id,
            type: that.trade_type,
            pay_user_id: that.trade_type == "WX" ? that.userInfor.openid : that.aliAccount.ali_user_id
          }).then(resPay => {
            console.log('大礼包支付', resPay);
            if (resPay.status == 200) {
              if (that.trade_type == 'WX') {
                that.paymentByWxpay(resPay.data)
              } else if (that.trade_type == 'ALI') {
                that.paymentByAlipay(resPay.data)
              }
            } else {
              alert(resPay.message)

            }
          }).catch(resPay => {
            alert(resPay.message)
          })
        } else {
          alert(res.message)
        }
      }).catch(res => {
        alert(res.message)
      })
    },
    // 微信支付
    paymentByWxpay(data) {
      var pay_info = JSON.parse(data.expend.pay_info)
      wx.config({
        debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印
        appId: pay_info.appId, // 必填，公众号的唯一标识
        timestamp: pay_info.timeStamp, // 必填，生成签名的时间戳
        nonceStr: pay_info.nonceStr, // 必填，生成签名的随机串
        signature: pay_info.paySign,// 必填，签名
        jsApiList: ['chooseWXPay'], // 必填，需要使用的 JS 接口列表 支付
        openTagList: ["wx-open-launch-weapp"] // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
      });

      wx.ready(function () {
        wx.checkJsApi({
          jsApiList: ['chooseWXPay'], // 需要检测的JS接口列表，所有JS接口列表见附录2,
          success: function (res) {
            console.log(res, '微信支付返回值');
          },
          fail: function (res) {
            console.log(res, '错误');
          }
        });
        wx.chooseWXPay({
          appId: pay_info.appId,
          timestamp: pay_info.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
          nonceStr: pay_info.nonceStr, // 支付签名随机串，不长于 32 位
          package: pay_info.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=***）
          signType: pay_info.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
          paySign: pay_info.paySign, // 支付签名
          success: (res) => {
            console.log(res);
            // 支付成功后的回调函数
            // location.replace("./Paytocomplete.html")
            // window.location.replace("./Paytocomplete.html")
            // window.location.href = path + "./Paytocomplete.html";
            //结束loading
          },
          // cancel: function (res) {
          //      结束loading
          //     alert('取消支付');
          // },
          fail: function (res) {
            //结束loading
            alert('支付失败', res);
          }
        });
      });
      wx.error(function (res) {
        console.log('res', res);
      });
    },
    // 支付宝支付
    paymentByAlipay(data) {
      let that = this;
      var zfbPay_info = JSON.parse(data.expend.pay_info)
      window.AlipayJSBridge.call("tradePay", {
        tradeNO: zfbPay_info.tradeNO, // 必传，此使用方式下该字段必传
      }, function (result) {
        if (result.resultCode == '9000') {
          location.replace("./Paytocomplete.html?out_trade_no=" + that.outTradeNo);
          that.paymentAmount = '';
        } else {
          console.log("支付失败");
          //   alert("支付失败")
        }
      });
    },
    readys(callback) {
      if (window.AlipayJSBridge) {
        callback && callback();
      } else {
        document.addEventListener('AlipayJSBridgeReady', callback, false);
      }
    },
  }
}
</script>
  
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

body {
  background: #F6F6F6;
}

* {
  margin: 0;
  padding: 0;
}

#pension {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #F6F6F6;
}

.pension-header {
  width: 100%;
}

.pension-open {
  margin: -26% 19.5px 0;
  position: relative;
}

.pension-openImg {
  width: 100%;
}

.openImg-title {
  position: absolute;
  top: 25px;
  left: 48px;
  width: 135px;
  font-size: 19px;
  color: #C76B35;
  font-weight: 600;
}

.openImg-amount {
  position: absolute;
  top: 50px;
  right: 48px;
  color: #D31710;
  font-size: 24px;
  font-weight: 600;
}

.amount-icon {
  font-size: 14px;
}

.pension-lists {
  margin: 10px 19.5px 0;
}

.pension-list {
  position: relative;
  margin-bottom: 20px;
}

.pension-list-img {
  width: 100%;
}

.pension-list-amount {
  position: absolute;
  top: 32px;
  left: 25px;
  font-weight: 600;
  color: #C76B35;
  font-size: 40px;
  display: flex;
}

.pension-list-amount-icon {
  font-size: 14px;
  font-weight: normal;
  margin-right: 6px;
  padding-top: 10px;
  align-self: center;
}

.pension-list-txt {
  position: absolute;
  top: 50%;
  right: 84px;
  transform: translate(0, -50%);
  width: 126px;
  font-size: 14px;
  color: #C76B35;
}

.pension-list-btn {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(0, -50%);
  text-align: center;
  display: flex;
  align-items: center;
}

.pension-list-btnImg {
  width: 61px;
  height: 61px;
}

.pension-list-btnTxt {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #C76B35;
}

.pension-list-pack {
  position: absolute;
  top: 4px;
  left: 4px;
  text-align: center;
  display: flex;
  align-items: center;
}

.pension-list-packImg {
  width: 56.5px;
  height: 20px;
}

.pension-list-packTxt {
  position: absolute;
  color: #ffffff;
  width: 100%;
}</style>
  