import axios from 'axios'
// import { Message, Loading } from 'element-ui'
import { Message } from 'element-ui'
const ConfigBaseURL = 'https://test.bjtdba.com' //默认路径，这里也可以使用env来判断环境
// const ConfigBaseURL = 'https://app.bjtdba.com' //
// let loadingInstance = null //这里是loading
  
export const Service = axios.create({
    timeout: 7000, // 请求超时时间
    baseURL: ConfigBaseURL,
    method: 'post',
    // headers: {
    //     'x-token': localStorage.getItem('token')
    // },
})
// 添加请求拦截器
Service.interceptors.request.use(config => {
    // loadingInstance = Loading.service({
    //     lock: true,
    //     text: 'loading...'
    // })

    //判断接口
    if (config.url == '/api/user/create_annual_order') {
        config.headers = {
            "x-token": localStorage.getItem('token')
        }
    }
    return config
})
// 添加响应拦截器
Service.interceptors.response.use(response => {
    // loadingInstance.close()
    // console.log(response)
    return response.data
}, error => {
    console.log('TCL: error', error)
    const msg = error.Message !== undefined ? error.Message : ''
    Message({
        message: '网络错误' + msg,
        type: 'error',
        duration: 1 * 1000
    })
    // loadingInstance.close()
})